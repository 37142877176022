<template>
  <div class="page" id="zid" :class="{ rtl }">
    <div class="navbar py-3">
      <div class="container mx-auto">
        <img
          :src="require('@/assets/images/logo.svg')"
          class="navbar-logo"
          alt="Innocalls"
        />
        <language-toggle class="nav-item" />
      </div>
    </div>

    <div class="container mx-auto mb-5">
      <div class="d-flex flex-column">
        <h2>{{ $t('zid.title') }}</h2>
        <half-circle-spinner
          :animation-duration="1500"
          :size="50"
          color="#74b9ff"
          v-if="loading"
          class="my-5 mx-auto"
        />
        <div class="form d-flex flex-column gap-2" v-else>
          <Field :label="$t('zid.form.activateTheService.label')">
            <b-form-checkbox
              v-model="model.serviceEnabled"
              name="check-button"
              size="lg"
              switch
            >
            </b-form-checkbox>
          </Field>
          <Field :label="$t('zid.form.editOrdersInZid.label')">
            <b-form-checkbox
              v-model="model.updateOrders"
              name="check-button"
              size="lg"
              switch
            >
            </b-form-checkbox>
          </Field>
          <Field
            :label="$t('zid.form.paymentMethod.label')"
            :error="errors.paymentsToConfirm"
          >
            <Select
              :multiple="true"
              class="bg-white"
              :options="paymentMethods"
              v-model="model.paymentsToConfirm"
              :placeholder="$t('zid.form.paymentMethod.placeholder')"
            >
              <template slot="beforeList">
                <div class="bg-white d-flex px-2">
                  <button
                    class="btn btn-sm btn-primary m-2"
                    @click="selectAllMethods"
                  >
                    {{ $t('zid.form.paymentMethod.selectAll') }}
                  </button>
                  <button
                    class="btn btn-sm btn-primary m-2"
                    @click="deselectAllMethods"
                  >
                    {{ $t('zid.form.paymentMethod.deselectAll') }}
                  </button>
                </div>
              </template>
            </Select>
          </Field>
          <Field
            type="number"
            v-model="model.trialsCount"
            :label="$t('zid.form.numberOfAttempts.label')"
            :placeholder="$t('zid.form.numberOfAttempts.placeholder')"
            :error="errors.trialsCount"
          >
          </Field>
          <Field
            type="number"
            v-model="model.minutesToWaitBeforeFirstTrial"
            :label="$t('zid.form.delayBeforeFirstTrial.label')"
            :placeholder="$t('zid.form.delayBeforeFirstTrial.placeholder')"
            :error="errors.minutesToWaitBeforeFirstTrial"
          >
          </Field>
          <Field
            type="number"
            v-model="model.delayMinutesBetweenTrials"
            :label="$t('zid.form.delayBetweenTrials.label')"
            :placeholder="$t('zid.form.delayBetweenTrials.placeholder')"
            :error="errors.delayMinutesBetweenTrials"
          >
          </Field>
          <Field
            :label="$t('zid.form.statusOnConfirm.label')"
            :error="errors.statusOnConfirm"
          >
            <b-select
              id="statusOnConfirm"
              name="statusOnConfirm"
              class="bg-white"
              v-model="model.statusOnConfirm"
            >
              <option value="" disabled selected>{{
                $t('zid.form.statusOnConfirm.placeholder')
              }}</option>
              <option
                v-for="(option, index) in orderStatuses"
                v-bind:key="index"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </Field>
          <Field
            :label="$t('zid.form.statusOnCancel.label')"
            :error="errors.statusOnCancel"
          >
            <b-select
              id="statusOnCancel"
              name="statusOnCancel"
              class="bg-white"
              v-model="model.statusOnCancel"
            >
              <option value="" disabled selected>{{
                $t('zid.form.statusOnCancel.placeholder')
              }}</option>
              <option
                v-for="(option, index) in orderStatuses"
                v-bind:key="index"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </Field>
          <Field
            :label="$t('zid.form.statusOnNoAnswer.label')"
            :error="errors.statusOnNoAnswer"
          >
            <b-select
              id="statusOnNoAnswer"
              name="statusOnNoAnswer"
              class="bg-white"
              v-model="model.statusOnNoAnswer"
            >
              <option value="" disabled selected>{{
                $t('zid.form.statusOnNoAnswer.placeholder')
              }}</option>
              <option
                v-for="(option, index) in orderStatuses"
                v-bind:key="index"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </Field>
          <button
            class="btn btn-primary"
            @click="saveConfiguration"
            :disabled="saveLoading"
          >
            <span v-if="saveLoading">
              <half-circle-spinner
                slot="loading"
                :animation-duration="1500"
                :size="25"
                color="#74b9ff"
                class="input-loader m-auto"
              />
            </span>
            <span v-else>
              {{ $t('zid.form.save') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Field from '../../elements/Field.vue'
import LanguageToggle from '../../components/admin/app-navbar/components/dropdowns/LanguageToggle'
import zidService from '../../services/zid.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import { object, string, boolean, number, array } from 'yup'
import { yupToKV } from '../../utils/yup'
import Select from '../../elements/Select.vue'

export default {
  data() {
    return {
      paymentMethods: [],
      loading: false,
      saveLoading: false,
      model: {
        serviceEnabled: false,
        updateOrders: false,
        paymentsToConfirm: [],
        trialsCount: 0,
        delayMinutesBetweenTrials: 0,
        minutesToWaitBeforeFirstTrial: 0,
        statusOnConfirm: '',
        statusOnCancel: '',
        statusOnNoAnswer: ''
      },
      errors: {},
      rtl: false
    }
  },
  async mounted() {
    const locale = localStorage.getItem('locale') || 'en'
    Vue.i18n.set(locale)

    if (!this.storeToken) {
      this.$router.push({ path: '/' })
    }

    try {
      this.loading = true
      await this.getPaymentMethods()
      await this.getFoundryConfiguration()
    } catch (error) {
      console.log(error)
      this.$router.push({ path: '/' })
    } finally {
      this.loading = false
    }
  },
  components: { Field, LanguageToggle, HalfCircleSpinner, Select },
  computed: {
    locale() {
      return Vue.i18n.locale()
    },
    storeToken() {
      return this.$route.query.token
    },
    configurationSchema() {
      return object().shape({
        serviceEnabled: boolean().required(
          this.$t('zid.errors.required.serviceEnabled')
        ),
        updateOrders: boolean().required(
          this.$t('zid.errors.required.updateOrders')
        ),
        paymentsToConfirm: array()
          .min(1, this.$t('zid.errors.length.paymentsToConfirm'))
          .required(this.$t('zid.errors.required.paymentsToConfirm')),
        trialsCount: number()
          .transform(value => +value)
          .min(2, this.$t('zid.errors.invalid.trialsCount'))
          .required(this.$t('zid.errors.required.trialsCount')),
        delayMinutesBetweenTrials: number()
          .transform(value => +value)
          .min(5, this.$t('zid.errors.invalid.delayMinutesBetweenTrials'))
          .required(this.$t('zid.errors.required.delayMinutesBetweenTrials')),
        minutesToWaitBeforeFirstTrial: number()
          .transform(value => +value)
          .min(0, this.$t('zid.errors.invalid.minutesToWaitBeforeFirstTrial'))
          .required(
            this.$t('zid.errors.required.minutesToWaitBeforeFirstTrial')
          ),
        statusOnConfirm: string()
          .trim()
          .required(this.$t('zid.errors.required.statusOnConfirm')),
        statusOnCancel: string()
          .trim()
          .required(this.$t('zid.errors.required.statusOnCancel')),
        statusOnNoAnswer: string()
          .trim()
          .required(this.$t('zid.errors.required.statusOnNoAnswer'))
      })
    },
    orderStatuses() {
      return [
        {
          name: this.$t('zid.form.order-status.new'),
          id: 'new'
        },
        {
          name: this.$t('zid.form.order-status.preparing'),
          id: 'preparing'
        },
        {
          name: this.$t('zid.form.order-status.ready'),
          id: 'ready'
        },
        {
          name: this.$t('zid.form.order-status.indelivery'),
          id: 'indelivery'
        },
        {
          name: this.$t('zid.form.order-status.delivered'),
          id: 'delivered'
        },
        {
          name: this.$t('zid.form.order-status.cancelled'),
          id: 'cancelled'
        }
      ]
    }
  },
  watch: {
    locale: function(val) {
      if (val === 'ar') {
        this.rtl = true
      } else {
        this.rtl = false
      }
    }
  },
  methods: {
    async getPaymentMethods() {
      const methods = await zidService.getPaymentMethods(this.storeToken)
      this.paymentMethods = methods
    },
    selectAllMethods() {
      this.model.paymentsToConfirm = this.paymentMethods
    },
    deselectAllMethods() {
      this.model.paymentsToConfirm = []
    },
    async getFoundryConfiguration() {
      const foundryConfiguration = await zidService.getFoundryConfiguration(
        this.storeToken
      )

      console.log(foundryConfiguration)

      const paymentsToConfirm = foundryConfiguration.paymentsToConfirm
        .map(code => this.paymentMethods.find(pm => pm.code === code))
        .filter(obj => obj !== undefined)
      this.model = {
        ...foundryConfiguration,
        paymentsToConfirm
      }
    },
    async saveConfiguration() {
      this.errors = {}
      await this.configurationSchema
        .validate(this.model, { abortEarly: false })
        .catch(err => {
          console.log(err)
          this.errors = yupToKV(err)
        })

      if (this.configurationSchema.isValidSync(this.model)) {
        try {
          this.saveLoading = true
          const data = {
            ...this.model,
            paymentsToConfirm: this.model.paymentsToConfirm.map(
              ({ code }) => code
            )
          }
          await zidService.updateFoundryConfiguration(this.storeToken, data)
          this.toast(this.$t('zid.toasts.saveSuccessfully'))
        } catch (error) {
          this.toast(error.response.data.message, {
            type: 'error'
          })
        } finally {
          this.saveLoading = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.gap-2 {
  gap: 0.5rem;
}
</style>

import { api } from '../axios'

const getPaymentMethods = async storeToken => {
  const res = await api.get(`zid/payment-methods/${storeToken}`)
  return res.data
}
const getFoundryConfiguration = async storeToken => {
  const res = await api.get(`zid/configuration/${storeToken}`)
  return res.data
}
const updateFoundryConfiguration = async (storeToken, payload) => {
  const res = await api.put(`zid/configuration/${storeToken}`, payload)
  return res.data
}

export default {
  getPaymentMethods,
  getFoundryConfiguration,
  updateFoundryConfiguration
}
